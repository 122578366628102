import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	globalConfirm: [],
};

export const globalConfirmSlice = createSlice({
	name: "globalConfirm",
	initialState,
	reducers: {
		setGlobalConfirm: (state, action) => {
			const module = action.payload.module;

			const newGlobalConfirmArray = state.globalConfirm.filter(
				(obj) => obj.module !== module
			);

			newGlobalConfirmArray.push({
				module: module,
				confirmMessage: action.payload.confirmMessage,
				onConfirm: action.payload.onConfirm,
				onCancel: action.payload.onCancel,
			});

			state.globalConfirm = newGlobalConfirmArray;
		},
		unsetGlobalConfirm: (state, action) => {
			const newGlobalConfirmArray = state.globalConfirm.filter(
				(obj) => obj.module !== action.payload.module
			);

			state.globalConfirm = newGlobalConfirmArray;
		},
	},
});

export const { setGlobalConfirm, unsetGlobalConfirm } = globalConfirmSlice.actions;

export default globalConfirmSlice.reducer;
