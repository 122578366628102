import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	token: "",
	username: "",
	roles: [],
	isMaker: false,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setUserPreferences: (state, action) => {
			state.token = action.payload.access_token;
			localStorage.setItem("1TrueIdToken", action.payload.access_token);
			localStorage.setItem("1TrueIdUsername", action.payload.username);
			localStorage.setItem("roles", JSON.stringify(action.payload.scope)); // Necessario usare il metodo
			// stringify in quanto le localStorage possono memorizzare soltanto stringhe e non oggetti o array
			state.username = action.payload.username;
			state.roles = action.payload.scope;
			state.isMaker = action.payload.isMaker;
		},
		unsetUserPreferences: (state) => {
			state.token = "";
			localStorage.removeItem("1TrueIdToken");
			localStorage.removeItem("1TrueIdUsername");
			localStorage.removeItem("roles");
			state.username = "";
			state.roles = [];
			state.isMaker = false;
		},
	},
});

export const { setUserPreferences, unsetUserPreferences } = authSlice.actions;

export default authSlice.reducer;
