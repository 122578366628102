import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	menuOpen: false,
	openTabs: [],
	activeTabId: null,
	searchTextClicked: false,
};

export const generalConfigSlice = createSlice({
	name: "generalConfig",
	initialState,
	reducers: {
		setMenuOpen: (state, action) => {
			state.menuOpen = action.payload;
		},
		setActiveTabId: (state, action) => {
			state.activeTabId = action.payload;
		},
		setOpenTabs: (state, action) => {
			state.openTabs.push(action.payload);
		},
		handleOpenTabs: (state, action) => {
			state.openTabs = action.payload;
		},
		unsetOpenTabs: (state) => {
			state.activeTabId = null;
			state.openTabs = [];
		},
		setSearchTextClicked: (state, action) => {
			state.searchTextClicked = action.payload;
		},
	},
});

export const { setMenuOpen } = generalConfigSlice.actions;
export const { setActiveTabId } = generalConfigSlice.actions;
export const { setOpenTabs } = generalConfigSlice.actions;
export const { setSearchTextClicked } = generalConfigSlice.actions;
export const { handleOpenTabs } = generalConfigSlice.actions;
export const { unsetOpenTabs } = generalConfigSlice.actions;

export default generalConfigSlice.reducer;
