import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	module: "",
};

export const moduleSlice = createSlice({
	name: "module",
	initialState,
	reducers: {
		setModule: (state, action) => {
			state.module = action.payload;
			localStorage.setItem("1trueIdModule", action.payload);
		},
		unsetModule: (state) => {
			state.module = "";
			localStorage.removeItem("1trueIdModule");
		},
	},
});

export const { setModule, unsetModule } = moduleSlice.actions;

export default moduleSlice.reducer;
