import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	globalMessage: [],
};

export const globalMessageSlice = createSlice({
	name: "globalMessage",
	initialState,
	reducers: {
		setGlobalMessage: (state, action) => {
			const module = action.payload.module;

			const newGlobalMessageArray = state.globalMessage.filter(
				(obj) => obj.module !== module
			);

			newGlobalMessageArray.push({
				module: module,
				title: action.payload.title,
				message: action.payload.message,
			});

			state.globalMessage = newGlobalMessageArray;
		},
		setGlobalError: (state, action) => {
			const module = action.payload.module;

			const newGlobalErrorArray = state.globalMessage.filter((obj) => obj.module !== module);

			let errorMessage = "";
			if (
				typeof action.payload.errorMessage === "string" ||
				action.payload.errorMessage instanceof String
			) {
				errorMessage = action.payload.errorMessage;
			} else {
				errorMessage = JSON.stringify(action.payload.errorMessage);
			}
			newGlobalErrorArray.push({
				module: module,
				title: action.payload.title,
				errorMessage: errorMessage,
			});

			state.globalMessage = newGlobalErrorArray;
		},
		unsetGlobalError: (state) => {
			const module = localStorage.getItem("1trueIdModule");

			const newGlobalErrorArray = state.globalMessage.filter((obj) => obj.module !== module);

			state.globalMessage = newGlobalErrorArray;
		},
	},
});

export const { setGlobalMessage, setGlobalError, unsetGlobalError } = globalMessageSlice.actions;

export default globalMessageSlice.reducer;
