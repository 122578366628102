import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authReducer";
import globalMessageReducer from "./features/globalMessageReducer";
import moduleReducer from "./features/moduleReducer";
import globalLoadingReducer from "./features/globalLoadingReducer";
import globalConfirmReducer from "./features/globalConfirmReducer";
import generalConfigReducer from "./features/generalConfigReducer";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		module: moduleReducer,
		globalMessage: globalMessageReducer,
		globalLoading: globalLoadingReducer,
		globalConfirm: globalConfirmReducer,
		generalConfig: generalConfigReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
