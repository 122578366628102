import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	globalLoading: [],
};

export const globalLoadingSlice = createSlice({
	name: "globalLoading",
	initialState,
	reducers: {
		activateLoading: (state, action) => {
			const module = action.payload;

			let index = state.globalLoading.indexOf(module);
			if (index === -1) {
				state.globalLoading.push(module);
			}
		},
		deactivateLoading: (state, action) => {
			const module = action.payload;

			let indexToRemove = state.globalLoading.indexOf(module);
			if (indexToRemove !== -1) {
				state.globalLoading.splice(indexToRemove, 1);
			}
		},
	},
});

export const { activateLoading, deactivateLoading } = globalLoadingSlice.actions;

export default globalLoadingSlice.reducer;
